<template>
  <b-card>

    <header-table title="مكاتب المنارة" @create="onAdd" />


    <data-table :pagination="false" ref="estatesTable" :fields="fields" ep="/offices">
      <template #cell(is_available)="row">
        {{ row.item.is_available ? "متاح" : "غير متاح" }}
      </template>
      <template #cell(services)="row">
        <ul>
          <li v-for="(item, i) in row.item.services" :key="i">
            {{ item.title }}
          </li>
        </ul>
      </template>
      <template #cell(actions)="row">

        <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)">
          <template #extend-dropdown-item>
            <b-dropdown-item v-can="{ slug: $route.meta.slug, action: 'O' }" @click="onUpdateServiseClicked(row.item)">
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">خدمات المكتب</span>
            </b-dropdown-item>
            
            <b-dropdown-item v-can="{ slug: $route.meta.slug, action: 'O' }" @click="onUpdateUserOfficeClicked(row.item)">
              <feather-icon icon="UserIcon" />
              <span class="align-middle ml-50">مستخدم المكتب</span>
            </b-dropdown-item>
          </template>
        </actions-table>
      </template>
    </data-table>
    <form-modal ref="estateModalOffice" :formSchema="estatesFormSchema" title="إضافة مكتب" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
    </form-modal>
    <form-modal 
      ref="estateModalService" 
      :formSchema="estatesFormSchema2" 
      title="إضافة خدمات" :loading="loading"
      v-model="activModelservice"
      @confirm="onModalServicesConfirmed">
      <template #service_ids="item">
        <FormSelect
            v-model="item.form.service_ids"
            :reduce="(x) => x.id"
            :getOptionLabel="(x) => x.title"
            id="service_ids"
            label="خدمات المكتب"
            :ep="`select/service-by-office/${id_row}`"
            rules="required"
            :multiple="true"
        />
      </template>
    </form-modal>
    <form-modal ref="estateModalUserOffice" :formSchema="estatesFormSchemaUserOffice" title="إضافة مستخدم للمكتب"
      :loading="loading" v-model="activModeUserOffice" @confirm="onModalServicesConfirmed">
    </form-modal>
  </b-card>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  VBToggle,
  VBTooltip,
  BCollapse,
  BImg,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import DataTable from "@/components/data-table/index";
import FormInput from "@/components/form-input/index";
import Ripple from "vue-ripple-directive";
import FormModal from "@/components/FormModal.vue";
import FormFile from "@/components/form-file/index.vue";
import { mapActions, mapGetters } from "vuex";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import HeaderTable from "@/components/data-table/components/header-table.vue";
import ActionsTable from "@/components/data-table/components/actions-table.vue";
import FormSelect from "@/components/form-select/index.vue";
export default {
  components: {
    BCard,
    DataTable,
    BButton,
    FormInput,
    BRow,
    BCol,
    FormModal,
    FormFile,
    BCollapse,
    VBTooltip,
    VBToggle,
    BImg,
    BCarousel,
    BCarouselSlide,
    BMediaAside,
    BMediaBody,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    HeaderTable,
    ActionsTable,
    FormSelect
},
  directives: {
    Ripple,
  },
  data: () => {
    return {
      id_row:'',
      imageSlide: [],
      staticImages: [],
      fields: [
        { key: "hall.id", label: "رقم الغرفة" },
        { key: "office_no", label: "رقم المكتب", sortable: true },
        { key: "services", label: "خدمات" },
        { key: "is_available", label: "الحالة" },
        { key: "actions", label: "" },
      ],
      activModel: false,
      activModelservice: false,
      activModeUserOffice: false,
      estatesFormSchema2: {
        service_ids: {
          key: "service_ids",
        },
      },
      estatesFormSchemaUserOffice: {
        office_users: {
          component: "FormSelect",
          key: "office_users",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.name,
            id: "office_users",
            label: "مستخدم المكتب",
            ep: "select/office-users",
            rules: "required",
          },
        },
      },
      estatesFormSchema: {

        is_available: {
          component: "FormSelect",
          key: "is_available",
          attrs: {
            id: "is_available",
            label: "الحالة",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "متاح", value: 1 },
              { label: " غير متاح ", value: 0 },
            ],
          },
        },
        office_no: {
          component: "FormInput",
          key: "office_no",
          attrs: {
            id: "office_no",
            label: "رقم المكتب",
            rules: "required",
          },
        },
        hall_id: {
          component: "FormSelect",
          key: "hall_id",
          attrs: {
            id: "hall_id",
            label: "الغرفة",
            rules: "required",
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.code,
            ep: "select/office-halls",
          },
        },
      },
      utils: {
        status: "",
      },
      filter: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    ...mapActions("settings/manageOffice", [
      "updateOffices",
      "createOffices",
      "deleteOffices",
      "updateServices",
      "updateUserOffice",
      "getUserOffice"
    ]),

    onAdd() {
      this.$refs.estateModalOffice.init({});
      this.activModel = true;
    },

    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        office_no: form.office_no,
        hall_id: form.hall.id,
        floor: form.floor,
        is_available: form.is_available,
      };
      this.$refs.estateModalOffice.init(toUpdate);
      this.activModel = true;
    },

    onUpdateServiseClicked(form) {
      this.id_row = form.id
      var toUpdate = {
        id: form.id,
        service_ids: form.services.map(item => (item.id))
      };
      this.$refs.estateModalService.init(toUpdate);
      this.activModelservice = true;
    },

    onUpdateUserOfficeClicked(form) {
      var toUpdate = {
        id: form.id,
        // service_ids:form.services.map(item=>(item.id))
      };
      this.$refs.estateModalUserOffice.init(toUpdate);
      this.activModeUserOffice = true;
    },

    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف النشاط", {
          title: "تأكيد حذف النشاط",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteOffices({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },

    onModalConfirmed(form) {
      if (form.id) {
        this.updateOffices({ id: form.id, data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createOffices({ data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },
    onModalServicesConfirmed(form) {
      if (form.id) {
        this.updateServices({ id: form.id, data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModelservice = false;
        });
      }
    },
    onModalUserOfficeConfirmed(form) {
      if (form.id) {
        this.updateServices({ id: form.id, data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModeUserOffice = false;
        });
      }
    },
  },

  computed: {
    ...mapGetters("settings/manageOffice", ["loading", "services"]),
  },
  created() {

  }
};
</script>
<style lang="scss" >
.top-m {
  padding-top: 7px;
}
</style>